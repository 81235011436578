import { useState } from 'react';
import ModalForm from '../ModalForm';
import Button, { ButtonClear, ButtonTypes } from '@/components/button/Button';
import useModals from '@/src/hooks/useModals';

export default function ModalConfirm({
    pageTitle,
    pageUrl,
    buttonTitle,
    buttonClass = '',
    children,
    cancelButton = true,
    disabled = false,
    isSubmitting = false,
    onConfirm,
}) {
    const { closeModal } = useModals();
    const [submitting, setSubmitting] = useState(false);

    async function onConfirmClick(event) {
        event?.preventDefault();
        if (typeof onConfirm === 'function') {
            setSubmitting(true);
            try {
                await onConfirm.call();
            } catch (e) {
                // nothing
            } finally {
                setSubmitting(false);
            }
        } else closeModal();
    }

    return (
        <ModalForm className={'modal--primary modal--subscription'} pageUrl={pageUrl} pageTitle={pageTitle}>
            {children}
            <div className="modal__btnset">
                <div className="modal__wrapper-btn">
                    <Button
                        className={'button--large ' + (disabled ? ButtonTypes.TYPE_DISABLED : buttonClass)}
                        title={buttonTitle}
                        submitting={submitting || isSubmitting}
                        disabled={disabled}
                        onClick={onConfirmClick}
                    />
                </div>
                {cancelButton && (
                    <div className="modal__wrapper-btn">
                        <ButtonClear title={'Отмена'} onClick={closeModal} />
                    </div>
                )}
            </div>
        </ModalForm>
    );
}
