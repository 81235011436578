import { ButtonPrimary, ButtonSecondary } from '@/components/button/Button';
import ModalForm from '@/components/modal/ModalForm';
import { format } from '@/src/modules/Common';
import ModalPartnersPhoneReplace from '@/components/modal/partners/ModalPartnersPhoneReplace';
import useModals from '@/src/hooks/useModals';
import { PartnerSubscriptions } from '@/src/constants/subscriptions';

/**
 * @param {Offer} offer
 * @param {String} phone
 * @param {BaseModel} model
 */
export default function ModalPartnersNoWayToReconnect({ offer, phone, model }) {
    const { openModal } = useModals();

    const subscription = offer.subscription;

    const onAnotherPhoneClick = () => {
        openModal(<ModalPartnersPhoneReplace offer={offer} model={model} />);
    };

    const onPartnerClick = () => {
        window.open(PartnerSubscriptions[subscription.id].link, '_blank');
    };

    return (
        <ModalForm
            className={'modal--primary modal--confirm-phone'}
            pageTitle={'Подписка уже была активирована для этого номера телефона'}
            pageUrl={'#partners-no-way-to-reconnect'}
        >
            <div className="modal__content-title">
                Подписка {subscription.title} уже была активирована для этого номера телефона
            </div>
            <div className="modal__content-description modal__content-description--secondary">
                {format(phone.slice(1) || '**********', '+7 (###) ###-##-##')}
            </div>
            <div className="modal__content-subtitle">
                Введите другой номер телефона <br />
                или отключите активную подписку
            </div>
            <div className="modal__btnset">
                <div className="modal__wrapper-btn">
                    <ButtonPrimary title={'Ввести другой номер'} type="submit" onClick={onAnotherPhoneClick} />
                </div>
                <div className="modal__wrapper-btn">
                    <ButtonSecondary
                        title={`Перейти в ${subscription.title}`}
                        className="button--large"
                        onClick={onPartnerClick}
                    />
                </div>
            </div>
        </ModalForm>
    );
}
