import { ButtonPrimary, ButtonSecondary } from '@/components/button/Button';
import ModalForm from '@/components/modal/ModalForm';
import { format } from '@/src/modules/Common';
import ModalPartnersPhoneReplace from '@/components/modal/partners/ModalPartnersPhoneReplace';
import useModals from '@/src/hooks/useModals';
import ModalConfirmSubscriptionActivate from '@/components/modal/purchases/ModalConfirmSubscriptionActivate';

/**
 * @param {Offer} offer
 * @param {String} phone
 * @param {BaseModel} model
 * @param {Object} contact
 */
export default function ModalPartnersNoTrial({ offer, phone, model, contact }) {
    const { openModal } = useModals();

    const onAnotherPhoneClick = () => {
        openModal(<ModalPartnersPhoneReplace offer={offer} model={model} />);
    };

    const onSubmit = () => {
        const params = phone ? { partner_phone: phone } : { partner_contact_id: contact.contact_id };
        openModal(<ModalConfirmSubscriptionActivate offer={offer} params={params} />);
    };

    return (
        <ModalForm
            className={'modal--primary modal--confirm-phone'}
            pageTitle={'Пробный период для этого номера телефона уже был использован ранее'}
            pageUrl={'#partners-no-trial'}
        >
            <div className="modal__content-title">Пробный период для этого номера телефона уже был использован</div>
            <div className="modal__content-description modal__content-description--secondary">
                {format(phone?.slice(1) || contact?.phone.slice(1) || '**********', '+7 (###) ###-##-##')}
            </div>
            <div className="modal__content-subtitle">
                Вы можете подключить подписку без пробного периода
                <br />
                или ввести другой номер телефона
            </div>
            <div className="modal__btnset">
                <div className="modal__wrapper-btn">
                    <ButtonPrimary title={`Подключить за ${offer.price} ₽`} type="submit" onClick={onSubmit} />
                </div>
                <div className="modal__wrapper-btn">
                    <ButtonSecondary title={'Ввести другой номер'} type="submit" onClick={onAnotherPhoneClick} />
                </div>
            </div>
        </ModalForm>
    );
}
