import { useEffect, useState } from 'react';
import { ButtonPrimary, ButtonSecondary } from '@/components/button/Button';
import useModals from '@/src/hooks/useModals';
import ModalForm from '@/components/modal/ModalForm';
import { format } from '@/src/modules/Common';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import ApiBackendException from '@/src/api/ApiBackendException';
import ModalConfirmSubscriptionActivate from '@/components/modal/purchases/ModalConfirmSubscriptionActivate';
import useNotifications from '@/src/hooks/useNotifications';
import ModalPartnersNoWayToReconnect from '@/components/modal/partners/ModalPartnersNoWayToReconnect';
import ModalPartnersPhoneReplace from '@/components/modal/partners/ModalPartnersPhoneReplace';
import ModalTNBInfo from '@/components/modal/tnb/ModalTNBInfo';
import ModalPartnersNoTrial from '@/components/modal/partners/ModalPartnersNoTrial';
import ModalPartnersNoWayToReconnectIviInBundle from '@/components/modal/partners/ModalPartnersNoWayToReconnectIviInBundle';

/**
 * @param {Offer} offer
 * @param {BaseModel} model
 * @param {?String} phone
 */
export default function ModalPartnersPhoneConfirm({ offer, model, phone }) {
    const { api } = useApiStore();
    const { openModal } = useModals();
    const { showError } = useNotifications();
    const [isSubmitting, setSubmitting] = useState(false);
    const [contact, setContact] = useState(null);

    useEffect(() => {
        api.generalInformation.getPermits().then((permits) => {
            if (permits.contact) {
                setContact(permits.contact);
            } else {
                openModal(<ModalPartnersPhoneReplace offer={offer} model={model} />);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api]);

    const subscription = offer.subscription;

    async function onConfirm() {
        setSubmitting(true);
        const params = phone ? { partner_phone: phone } : { partner_contact_id: contact.contact_id };
        try {
            await api.billing.partnerPhoneCheck(subscription.id, phone ? null : contact.contact_id, phone);
            if (offer.stock?.isTNB()) {
                openModal(<ModalTNBInfo offer={offer} model={model} params={params} />);
            } else {
                openModal(<ModalConfirmSubscriptionActivate offer={offer} params={params} />);
            }
        } catch (e) {
            if (e instanceof ApiBackendException) {
                switch (e.apiError.message) {
                    case 'trial is not available for this phone number':
                    case 'subscription is active, reconnect the dom.ru without trial':
                        openModal(<ModalPartnersNoTrial offer={offer} model={model} phone={phone} contact={contact} />);
                        break;
                    case 'subscription is active, there is no way to reconnect':
                        openModal(
                            <ModalPartnersNoWayToReconnect offer={offer} phone={phone || contact.phone} model={model} />
                        );
                        break;
                    case 'subscription ivi is already active for this phone number':
                        openModal(
                            <ModalPartnersNoWayToReconnectIviInBundle
                                offer={offer}
                                phone={phone || contact.phone}
                                model={model}
                            />
                        );
                        break;
                    case 'okko subscription is already active':
                    // TODO
                    default:
                        showError(e.message || 'Ошибка при покупке подписки');
                }
            } else {
                showError(e.message || 'Ошибка при покупке подписки');
            }
            setSubmitting(false);
        }
    }

    const onAnotherPhoneClick = () => {
        openModal(<ModalPartnersPhoneReplace offer={offer} model={model} />);
    };

    return (
        <ModalForm
            className={'modal--primary modal--confirm-phone'}
            pageTitle={'Подтвердите номер телефона'}
            pageUrl={'#partners-phone-confirm'}
        >
            <div className="modal__content-title">Подтвердите номер телефона</div>
            <div className="modal__content-subtitle modal__content-subtitle--secondary">
                К этому номеру будет привязан ваш аккаунт для входа в {subscription.partnerTitle}
            </div>
            <div className="modal__content-description">
                {format(phone?.slice(1) || contact?.phone.slice(1) || '**********', '+7 (###) ###-##-##')}
            </div>
            <div className="modal__btnset">
                <div className="modal__wrapper-btn">
                    <ButtonPrimary
                        title={'Подтвердить номер'}
                        type="submit"
                        submitting={isSubmitting}
                        onClick={onConfirm}
                        disabled={!contact}
                    />
                </div>
                <div className="modal__wrapper-btn">
                    <ButtonSecondary
                        title={'Изменить номер'}
                        className="button--large"
                        onClick={onAnotherPhoneClick}
                        disabled={isSubmitting || !contact}
                    />
                </div>
            </div>
        </ModalForm>
    );
}
