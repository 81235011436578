import ModalPinCode from '@/components/modal/pin/ModalPinCode';
import { usePinStore } from '@/src/stores/RootStoreProvider';
import useModals from '@/src/hooks/useModals';

/**
 * @callback PinCheckFunction
 * @param {Function} callback - callback с параметром - введеный ПИН
 * @param {Boolean} [closeModalWhenSuccess=true] - Закрывать ли модалку с ПИН
 * @returns {void}
 */

/**
 * Возвращает функцию для проверки ПИН.
 * Функция запрашивает ПИН, при успешной проверке вызывает callback с параметром - введеный ПИН
 * @returns {PinCheckFunction}
 */
export default function usePinCheck() {
    const { openModal } = useModals();
    const { setPinCode } = usePinStore();

    return (callback, closeModalWhenSuccess = true) => {
        openModal(
            <ModalPinCode
                callback={(pinCode) => {
                    setPinCode(pinCode);
                    if (callback && typeof callback === 'function') {
                        callback(pinCode);
                    }
                }}
                closeModalWhenSuccess={closeModalWhenSuccess}
            />
        );
    };
}
