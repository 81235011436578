import { useState } from 'react';
import ModalForm from '@/components/modal/ModalForm';
import { ButtonPrimary } from '@/components/button/Button';
import usePurchases from '@/src/hooks/usePurchases';
import { PaymentType } from '@/src/constants/payment-type';
import usePurchasePinCheck from '@/src/hooks/usePurchasePinCheck';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import { STOCK_NOTICE, StockTypes } from '@/src/api/v3/models/Stock';
import useNotifications from '@/src/hooks/useNotifications';
import ChannelPackage from '@/src/api/v3/models/ChannelPackage';
import ModalConfirmPurchase from '@/components/modal/purchases/ModalConfirmPurchase';
import Bundle from '@/src/api/v3/models/Bundle';

/**
 * @param {Offer} offer
 * @param {BaseModel} model
 * @param {Object} params
 */
export default function ModalTNBInfo({ offer, model, params }) {
    const { api } = useApiStore();
    const { subscriptionActivate, channelPackageActivate, bundleActivate } = usePurchases();
    const { showError } = useNotifications();

    const [submitting, setSubmitting] = useState(false);

    const subscription = offer.subscription;

    async function onConfirm(paymentType = PaymentType.ACCOUNT) {
        setSubmitting(true);
        try {
            if (model instanceof ChannelPackage) {
                const devices = await api.billing.getBillingDevices();
                if (devices.length === 0) {
                    showError('Нет устройств для подключения');
                } else {
                    await channelPackageActivate({
                        offer,
                        model: subscription,
                        paymentType: paymentType,
                        deviceTrName: devices[0].trName,
                        params:
                            paymentType === PaymentType.ACCOUNT
                                ? { stock_type: StockTypes.TRY_AND_BUY }
                                : { use_tnb: 1 },
                    });
                }
            } else if (model instanceof Bundle) {
                await bundleActivate({
                    offer,
                    model: subscription,
                    paymentType: paymentType,
                    params: {
                        use_tnb: 1,
                        ...params,
                    },
                });
            } else {
                await subscriptionActivate({
                    offer,
                    model: subscription,
                    paymentType: paymentType,
                    params: {
                        close_with_ra: 2,
                        ...(paymentType === PaymentType.ACCOUNT
                            ? { stock_type: StockTypes.TRY_AND_BUY }
                            : { use_tnb: 1 }),
                        ...params,
                    },
                });
            }
        } catch (e) {
            setSubmitting(false);
        }
    }

    return (
        <ModalTNBWrapper
            offer={offer}
            pageTitle={'Подключение подписки ' + subscription.title}
            onConfirm={onConfirm}
            submitting={submitting}
        >
            <div className="modal__img-wrapper modal__img-wrapper--shadow">
                <img className="modal__img" src={model.getTNBBackgroundSrc()} width={605} height={284} alt="" />
                <div className="modal__try-buy">
                    <div className="modal__try-buy-label">{offer.stock.getTitle()}</div>
                    <div className="modal__try-buy-sub-label">Сообщим за&nbsp;3&nbsp;дня до&nbsp;окончания</div>
                    <img className="modal__try-buy-img" src="/assets/img/modal-try-buy.svg" alt="" />
                </div>
            </div>
            <div className="modal__content-title">
                {subscription.title}
                <br />
                {offer.stock.getTitle().toLowerCase()}
            </div>
            <div className="modal__content-subtitle">
                {offer.getStockInfo()}
                <br /> {STOCK_NOTICE}
            </div>
        </ModalTNBWrapper>
    );
}

function ModalTNBWrapper({ offer, pageTitle, onConfirm, submitting, children }) {
    const purchasePinCheck = usePurchasePinCheck();
    return offer.stock?.priceWithDiscount > 0 ? (
        <ModalConfirmPurchase pageTitle={pageTitle} onConfirm={onConfirm}>
            {children}
        </ModalConfirmPurchase>
    ) : (
        <ModalForm
            closeButton={true}
            className={'modal--primary modal--try-buy-send'}
            pageUrl={'#try-and-buy'}
            pageTitle={'Подключить и смотреть'}
        >
            {children}
            <div className="modal__btnset">
                <div className="modal__wrapper-btn">
                    <ButtonPrimary
                        title={`Попробуйте ${offer.stock.getPeriodText()}`}
                        className="button--large"
                        onClick={() => {
                            purchasePinCheck(onConfirm);
                        }}
                        submitting={submitting}
                    />
                </div>
            </div>
        </ModalForm>
    );
}
