import useNotifications from '@/src/hooks/useNotifications';
import usePurchases from '@/src/hooks/usePurchases';
import ModalConfirmPurchase from '@/components/modal/purchases/ModalConfirmPurchase';
import { useApiStore } from '@/src/stores/RootStoreProvider';

/**
 * @param {Offer} offer
 */
export default function ModalConfirmChannelPackageActivate({ offer }) {
    const { api } = useApiStore();
    const subscription = offer.subscription;
    const { showError } = useNotifications();
    const { channelPackageActivate } = usePurchases();

    async function onConfirm(paymentType) {
        const devices = await api.billing.getBillingDevices();
        if (devices.length === 0) {
            showError('Нет устройств для подключения');
        } else {
            await channelPackageActivate({
                offer,
                model: subscription,
                paymentType,
                deviceTrName: devices[0].trName,
            });
        }
    }

    return (
        <ModalConfirmPurchase
            pageTitle={'Подключение подписки на пакет каналов ' + subscription.title}
            pageUrl={'#channel-package-activate'}
            onConfirm={onConfirm}
        >
            <div className="modal__content-title">
                Стоимость подписки <nobr>{offer.price} ₽ в месяц</nobr>
            </div>
            <div className="modal__content-subtitle">
                Стоимость подписки на пакет каналов «{subscription.title}»<nobr> {offer.price} ₽ в месяц</nobr>. Каналы
                будут доступны на всех ваших устройствах.
            </div>
        </ModalConfirmPurchase>
    );
}
