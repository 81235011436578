import { useApi } from '@/src/hooks/useApi';
import Icon from '@/components/icon/Icon';
import { useState } from 'react';
import ModalConfirm from '@/components/modal/purchases/ModalConfirm';
import { useApiStore, useAuthStore } from '@/src/stores/RootStoreProvider';
import { PaymentType } from '@/src/constants/payment-type';
import usePurchasePinCheck from '@/src/hooks/usePurchasePinCheck';

/**
 * @param children
 * @param onConfirm
 * @param pageTitle
 * @param pageUrl
 * @param paymentTypes
 * @param closePinModalWhenSuccess
 */
export default function ModalConfirmPurchase({
    children,
    onConfirm,
    pageTitle,
    pageUrl,
    paymentTypes = [PaymentType.CARD, PaymentType.ACCOUNT],
    closePinModalWhenSuccess = true,
}) {
    const { api } = useApiStore();
    const { isOTT } = useAuthStore();
    const purchasePinCheck = usePurchasePinCheck();
    const balance = /** @type {Number} */ useApi(api.billing.getBalance, isOTT === false);
    const cards = /** @type {PaymentCard[]} */ useApi(api.payment.getPaymentCards);
    const [paymentType, setPaymentType] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    function onPaymentMethodChange(event) {
        let value = event.target.value;
        setPaymentType(value);
        setDisabled(false);
    }

    const balanceTitle = balance === undefined ? '' : `${balance}&nbsp;₽`;

    const onPurchaseClick = async () => {
        setIsSubmitting(true);
        try {
            await onConfirm(paymentType);
        } catch (e) {
            // nothing
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <ModalConfirm
            pageTitle={pageTitle}
            pageUrl={pageUrl}
            buttonTitle={'Оплатить'}
            cancelButton={false}
            disabled={disabled}
            onConfirm={() => {
                purchasePinCheck(onPurchaseClick, closePinModalWhenSuccess);
            }}
            isSubmitting={isSubmitting}
        >
            {children}
            <div className="modal__fieldset">
                <div className="modal__paragraph">Выберите наиболее удобный способ оплаты</div>
                {!isOTT && paymentTypes.includes(PaymentType.ACCOUNT) && (
                    <div className="modal__field modal__field--checkbox">
                        <RadioButton
                            title={`Баланс лицевого счёта: ${balanceTitle}`}
                            id="payment-method-account"
                            value={PaymentType.ACCOUNT}
                            checked={paymentType === PaymentType.ACCOUNT}
                            onChange={onPaymentMethodChange}
                        />
                    </div>
                )}
                {paymentTypes.includes(PaymentType.CARD) && cards !== undefined && (
                    <div className="modal__field modal__field--checkbox">
                        <RadioButton
                            title={'Банковской картой' + (cards?.length ? ' ' + cards[0].getTitle() : '')}
                            id="payment-method-card"
                            value={PaymentType.CARD}
                            checked={paymentType === PaymentType.CARD}
                            onChange={onPaymentMethodChange}
                        />
                    </div>
                )}
            </div>
        </ModalConfirm>
    );
}

function RadioButton({ ref, title, ...props }) {
    return (
        <div className="input">
            <input id="radio" {...props} className="input__radio" type="radio" ref={ref} />
            <div className="input__radio-figure">
                <div className="input__radio-border"> </div>
                <div className="input__radio-cover"> </div>
                <Icon id={'dot'} modifier={'input__radio-icon icon--accept'} />
            </div>
            <label className="input__label" htmlFor={props.id || 'radio'} dangerouslySetInnerHTML={{ __html: title }} />
        </div>
    );
}
