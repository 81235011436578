import { usePinStore } from '@/src/stores/RootStoreProvider';
import usePinCheck from '@/src/hooks/usePinCheck';
import ModalPurchaseContinuation from '@/components/modal/purchases/ModalPurchaseContinuation';
import useModals from '@/src/hooks/useModals';

/**
 * @callback PinCheckFunction
 * @param {Function} callback - callback с параметром - введеный ПИН
 * @param {Boolean} [closeModalWhenSuccess=true] - Закрывать ли модалку с ПИН
 * @returns {void}
 */

/**
 * Возвращает функцию для проверки ПИН при включенной настройке purchaseConfirmationEnabled
 * Функция запрашивает ПИН, при успешной проверке вызывает callback с параметром - введеный ПИН
 * @returns {PinCheckFunction}
 */
export default function usePurchasePinCheck() {
    const { /** @type {PinStatus} */ pinStatus } = usePinStore();
    const pinCheck = usePinCheck();
    const { openModal } = useModals();

    return (callback, closeModalWhenSuccess = true) => {
        if (pinStatus?.isEnabled() && pinStatus?.purchaseConfirmationEnabled) {
            const pinCallback = () => {
                if (closeModalWhenSuccess) {
                    openModal(<ModalPurchaseContinuation />);
                }
                callback();
            };
            pinCheck(pinCallback, closeModalWhenSuccess);
        } else {
            callback();
        }
    };
}
