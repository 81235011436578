import useNotifications from '@/src/hooks/useNotifications';
import useCardPayments from '@/src/hooks/useCardPayments';
import { PurchaseType } from '@/src/constants/purchase-type';
import { PaymentType } from '@/src/constants/payment-type';
import Offer from '@/src/api/v3/models/Offer';
import apiCache from '@/src/api/ApiCache';
import { TARIFF_PLUS_ID, TARIFF_STANDARD_ID } from '@/src/api/v2/models/WatchEverywhereStatus';
import useModals from '@/src/hooks/useModals';
import ModalNotEnoughMoney from '@/components/modal/purchases/ModalNotEnoughMoney';
import ModalTNBInfo from '@/components/modal/tnb/ModalTNBInfo';
import ChannelPackage from '@/src/api/v3/models/ChannelPackage';
import ModalConfirmChannelPackageActivate from '@/components/modal/purchases/ModalConfirmChannelPackageActivate';
import ModalConfirmSubscriptionActivate from '@/components/modal/purchases/ModalConfirmSubscriptionActivate';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import Subscription from '@/src/api/v3/models/Subscription';
import ModalConfirmAssetPurchase from '@/components/modal/purchases/ModalConfirmAssetPurchase';
import ModalPartnersPhoneConfirm from '@/components/modal/partners/ModalPartnersPhoneConfirm';
import Stock from '@/src/api/v3/models/Stock';
import Bundle from '@/src/api/v3/models/Bundle';
import { Subscriptions } from '@/src/constants/subscriptions';
import { gtmEvent } from '@/src/modules/events/GTM';

// noinspection ExceptionCaughtLocallyJS
export default function usePurchases() {
    const { api } = useApiStore();
    const { openModal, closeModal } = useModals();
    const { showError, showSuccess } = useNotifications();
    const { purchaseByCard } = useCardPayments();

    /**
     * Asset Purchase
     * @param {Offer} offer
     * @param {BaseModel} model
     * @param {string} paymentType
     * @returns {void}
     */
    const assetPurchase = async ({ offer, model, paymentType = PaymentType.ACCOUNT }) => {
        const eventParams = {
            item_id: model.id,
            item_name: model.title,
            item_category: model.EVENT_ITEM_CATEGORY,
            is_tnb: offer.stock?.isTNB() ? 1 : 0,
        };
        try {
            switch (paymentType) {
                case PaymentType.ACCOUNT:
                    await api.billing.purchase(model.id, offer.id);
                    showSuccess({ title: 'Покупка оплачена', message: 'Теперь фильм доступен для просмотра' }, true);
                    gtmEvent('purchase', 'checkout_end', { ...eventParams, payment_method: PaymentType.ACCOUNT });
                    window.location.reload();
                    break;
                case PaymentType.CARD:
                    await purchaseByCard({ offer, id: model.id, purchaseType: PurchaseType.ASSET, eventParams });
                    break;
                default:
                // Nothing
            }
        } catch (error) {
            if (['AGREEMENT_NOT_ACTIVE', 'INSUFFICIENT_CREDIT_BALANCE'].includes(error.code || '')) {
                openModal(<ModalNotEnoughMoney error={error} gaEventType={'asset'} />);
            } else {
                showError(error.message || 'Ошибка при покупке ассета');
                if (document.title === 'Завершение покупки') {
                    closeModal();
                }
            }
            throw new Error(error);
        }
    };

    /**
     * Subscription Activate
     * @param {Offer} offer
     * @param {BaseModel} model
     * @param {Object} params
     * @param {string} paymentType
     * @returns {void}
     */
    const subscriptionActivate = async ({ offer, model, params = {}, paymentType = PaymentType.ACCOUNT }) => {
        const eventParams = {
            item_id: model.id,
            item_name: model.title,
            item_category: model.EVENT_ITEM_CATEGORY,
            is_tnb: offer.stock?.isTNB() ? 1 : 0,
        };
        try {
            switch (paymentType) {
                case PaymentType.ACCOUNT:
                    await api.billing.purchase(model.id, offer.id, params);
                    apiCache.removeSubscriptionCaches();
                    showSuccess(
                        {
                            title: 'Подписка подключена',
                            message: 'Теперь фильмы из подписки доступны для просмотра',
                            subMessage: [Subscriptions.KINOPOISK, Subscriptions.MOVIX_ONE].includes(model.id)
                                ? 'Для продолжения нажмите на кнопку «Активировать подписку Плюс».'
                                : '',
                        },
                        true
                    );
                    gtmEvent('purchase', 'checkout_end', { ...eventParams, payment_method: PaymentType.ACCOUNT });
                    window.location.reload();
                    break;
                case PaymentType.CARD:
                    await purchaseByCard({
                        offer,
                        id: model.id,
                        purchaseType: PurchaseType.SUBSCRIPTION,
                        params,
                        eventParams,
                    });
                    break;
                default:
                // Nothing
            }
        } catch (error) {
            handleError(
                error,
                offer.stock?.isTNB() ? 'subscription-tnb' : 'subscription',
                'Ошибка при при покупке подписки'
            );
            throw new Error(error);
        }
    };

    /**
     * Bundle Activate
     * @param {Offer} offer
     * @param {BaseModel} model
     * @param {Object} params
     * @param {string} paymentType
     * @returns {void}
     */
    const bundleActivate = async ({ offer, model, params = {}, paymentType = PaymentType.ACCOUNT }) => {
        const eventParams = {
            item_id: model.id,
            item_name: model.title,
            item_category: model.EVENT_ITEM_CATEGORY,
            is_tnb: offer.stock?.isTNB() ? 1 : 0,
        };
        try {
            switch (paymentType) {
                case PaymentType.ACCOUNT:
                    // В API активации бандла параметр partner_phone называется partner_phone_number
                    if (params['partner_phone']) {
                        params['partner_phone_number'] = params['partner_phone'];
                        delete params['partner_phone'];
                    }
                    await api.billing.bundleActivate(model.id, offer.id, params);
                    apiCache.removeBundleCaches();
                    showSuccess(
                        {
                            title: 'Подписка подключена',
                            message: 'Теперь фильмы из подписки доступны для просмотра.',
                            subMessage: 'Для продолжения нажмите на кнопку «Активировать подписку Плюс».',
                        },
                        true
                    );
                    gtmEvent('purchase', 'checkout_end', { ...eventParams, payment_method: PaymentType.ACCOUNT });
                    window.location.reload();
                    break;
                case PaymentType.CARD:
                    await purchaseByCard({
                        offer,
                        id: model.id,
                        purchaseType: PurchaseType.BUNDLE,
                        params,
                        eventParams,
                    });
                    break;
                default:
                // Nothing
            }
        } catch (error) {
            handleError(error, 'bundle', 'Ошибка при при покупке подписки');
            throw new Error(error);
        }
    };

    /**
     * Subscription Deactivate
     * @param {Offer} offer
     * @returns {void}
     */
    const subscriptionDeactivate = async (offer) => {
        const subscription = offer.subscription;
        try {
            await api.billing.cancelSVOD(subscription.id, offer.id);
            apiCache.removeSubscriptionCaches();
            showSuccess(
                {
                    title: 'Подписка отключена',
                    message: 'Вы всегда можете восстановить её в разделе «Подписки»',
                },
                true
            );
            window.location.reload();
        } catch (error) {
            showError(error.message || 'Ошибка при отключении подписки');
            throw new Error(error);
        }
    };

    /**
     * Channel Package Activate
     * @param {Offer} offer
     * @param {BaseModel} model
     * @param {string} deviceTrName
     * @param {string} paymentType
     * @param {Object} params
     * @returns {void}
     */
    const channelPackageActivate = async ({
        offer,
        model,
        params = {},
        deviceTrName,
        paymentType = PaymentType.ACCOUNT,
    }) => {
        const eventParams = {
            item_id: model.id,
            item_name: model.title,
            item_category: model.EVENT_ITEM_CATEGORY,
            is_tnb: offer.stock?.isTNB() ? 1 : 0,
        };
        try {
            switch (paymentType) {
                case PaymentType.ACCOUNT:
                    await api.billing.activateChannelPackage(model.id, deviceTrName, params);
                    apiCache.removeSubscriptionCaches();
                    showSuccess(
                        {
                            title: 'Пакет каналов подключен',
                            message: 'Теперь каналы из пакета доступны для просмотра',
                        },
                        true
                    );
                    gtmEvent('purchase', 'checkout_end', { ...eventParams, payment_method: PaymentType.ACCOUNT });
                    window.location.reload();
                    break;
                case PaymentType.CARD:
                    await purchaseByCard({
                        offer,
                        id: model.id,
                        purchaseType: PurchaseType.CHANNEL_PACKAGE,
                        params,
                        eventParams,
                    });
                    break;
                default:
                // Nothing
            }
        } catch (error) {
            handleError(error, 'channel-package', 'Ошибка при активации услуги "Управляй просмотром"');
            throw new Error(error);
        }
    };

    /**
     * Channel Package Deactivate
     * @param {Offer} offer
     * @param {string|Array} deviceTrName
     * @returns {void}
     */
    const channelPackageDeactivate = async (offer, deviceTrName) => {
        const subscription = offer.subscription;
        try {
            const trNames = Array.isArray(deviceTrName) ? deviceTrName : [deviceTrName];
            for (const trName of trNames) {
                await api.billing.deactivateChannelPackage(subscription.id, trName);
            }
            apiCache.removeSubscriptionCaches();
            showSuccess(
                {
                    title: 'Пакет каналов отключен',
                    message: 'Вы всегда можете восстановить его в разделе «Пакеты каналов»',
                },
                true
            );
            window.location.reload();
        } catch (error) {
            showError(error.message || 'Ошибка при отключении пакета каналов');
            throw new Error(error);
        }
    };

    /**
     * Bundle Deactivate
     * @param {Offer} offer
     * @returns {void}
     */
    const bundleDeactivate = async (offer) => {
        const subscription = offer.subscription;
        try {
            await api.billing.bundleDeactivate(subscription.id, offer.id);
            apiCache.removeBundleCaches();
            showSuccess(
                {
                    title: 'Подписка отключена',
                    message: 'Вы всегда можете восстановить её в разделе «Подписки»',
                },
                true
            );
            window.location.reload();
        } catch (error) {
            showError(error.message || 'Ошибка при отключении подписки');
            throw new Error(error);
        }
    };

    /**
     * Catchup Activate
     * @param {number} price
     * @param {string} paymentType
     * @param {Boolean} isTNB
     * @returns {void}
     */
    const catchupActivate = async ({ price, paymentType = PaymentType.ACCOUNT, isTNB = false }) => {
        try {
            switch (paymentType) {
                case PaymentType.ACCOUNT:
                    await api.catchup.activateCatchup(isTNB);
                    apiCache.removeCatchupCaches();
                    showSuccess(
                        { title: 'Услуга подключена', message: 'Теперь услуга доступна для использования' },
                        true
                    );
                    window.location.reload();
                    break;
                case PaymentType.CARD: {
                    const offer = new Offer({ id: 0, price: price });
                    if (isTNB) {
                        offer.stock = new Stock({ price: price });
                    }
                    const params = isTNB ? { use_tnb: 1 } : {};
                    await purchaseByCard({ offer, purchaseType: PurchaseType.CATCHUP, params });
                    break;
                }
                default:
                // Nothing
            }
        } catch (error) {
            handleError(error, 'catchup', 'Ошибка при активации услуги "Управляй просмотром"');
            throw new Error(error);
        }
    };

    /**
     * Catchup Deactivate
     * @returns {void}
     */
    const catchupDeactivate = async () => {
        try {
            await api.catchup.deactivateCatchup();
            apiCache.removeCatchupCaches();
            showSuccess(
                {
                    title: 'Услуга отключена',
                    message: 'Вы всегда можете восстановить её в разделе «Смотри в записи»',
                },
                true
            );
            window.location.reload();
        } catch (error) {
            showError(error.message || 'Ошибка при отключении услуги "Управляй просмотром"');
            throw new Error(error);
        }
    };

    /**
     * WatchEverywhere Activate
     * @param {number} price
     * @param {number} serviceId
     * @param {string} serviceName
     * @param {number} maxSlotCount
     * @param {string} paymentType
     * @param {boolean} reloadWindowAfterPurchase
     * @returns {void}
     */
    const watchEverywhereActivate = async ({
        price,
        serviceId,
        serviceName,
        maxSlotCount,
        paymentType = PaymentType.ACCOUNT,
        reloadWindowAfterPurchase = true,
    }) => {
        try {
            switch (paymentType) {
                case PaymentType.ACCOUNT:
                    await api.watchEverywhere.activateWatchEverywhere(serviceId);
                    showSuccess(
                        {
                            title: `Услуга ${serviceName} подключена`,
                            message: `Теперь вы можете смотреть Movix сразу на ${maxSlotCount} устройствах`,
                        },
                        reloadWindowAfterPurchase
                    );
                    if (reloadWindowAfterPurchase) {
                        window.location.reload();
                    }
                    break;
                case PaymentType.CARD: {
                    const offer = new Offer({ id: 0, price: price });
                    await purchaseByCard({ offer, id: serviceId, purchaseType: PurchaseType.WATCH_EVERYWHERE });
                    break;
                }
                default:
                // Nothing
            }
        } catch (error) {
            handleError(error, 'watch-everywhere', 'Ошибка при активации услуги "Смотри везде"');
            throw new Error(error);
        }
    };

    /**
     * WatchEverywhere Deactivate
     * @returns {void}
     */
    const watchEverywhereDeactivate = async (serviceId) => {
        try {
            if (serviceId === TARIFF_PLUS_ID) {
                await api.watchEverywhere.activateWatchEverywhere(TARIFF_STANDARD_ID);
            } else {
                await api.watchEverywhere.deactivateWatchEverywhere();
            }
            apiCache.removeWatchEverywhereCaches();
            showSuccess(
                {
                    title: 'Услуга отключена',
                    message: 'Вы всегда можете восстановить её в разделе «Услуги»',
                },
                true
            );
        } catch (e) {
            showError(e.message || 'Ошибка при отключении услуги "Смотри везде"');
            throw new Error(e);
        }
    };

    /**
     * @param {Asset|Subscription|ChannelPackage} model
     * @param {Offer} offer
     */
    const onPurchaseModel = (model, offer = model.offers.offer) => {
        let modelCategory;
        let eventModel = model;
        switch (true) {
            case model instanceof Bundle:
            case model instanceof ChannelPackage:
            case model instanceof Subscription:
                modelCategory = model.EVENT_ITEM_CATEGORY;
                break;
            case Boolean(offer.subscription):
                modelCategory = offer.subscription.EVENT_ITEM_CATEGORY;
                eventModel = offer.subscription;
                break;
            default:
                modelCategory = model.EVENT_ITEM_CATEGORY;
        }
        gtmEvent('purchase', 'checkout_begin', {
            item_id: eventModel.id,
            item_name: eventModel.title,
            item_category: modelCategory,
            is_tnb: offer.stock?.isTNB() ? 1 : 0,
        });
        if (offer.subscription?.isPartner() || model instanceof Bundle) {
            openModal(<ModalPartnersPhoneConfirm offer={offer} model={model} />);
        } else if (offer.stock?.isTNB()) {
            openModal(<ModalTNBInfo offer={offer} model={model} />);
        } else if (model instanceof ChannelPackage) {
            openModal(<ModalConfirmChannelPackageActivate offer={offer} />);
        } else if (model instanceof Subscription || offer.subscription) {
            openModal(<ModalConfirmSubscriptionActivate offer={offer} />);
        } else {
            openModal(<ModalConfirmAssetPurchase offer={offer} model={model} />);
        }
    };

    /**
     * @param {Object} error
     * @param {string} gaEventType
     * @param {string} message
     */
    const handleError = (error, gaEventType, message) => {
        if (['AGREEMENT_NOT_ACTIVE', 'INSUFFICIENT_CREDIT_BALANCE'].includes(error.code || '')) {
            openModal(<ModalNotEnoughMoney error={error} gaEventType={gaEventType} />);
        } else {
            showError(error.message || message);
            if (document.title === 'Завершение покупки') {
                closeModal();
            }
        }
    };

    return {
        assetPurchase,
        subscriptionActivate,
        subscriptionDeactivate,
        bundleActivate,
        bundleDeactivate,
        channelPackageActivate,
        channelPackageDeactivate,
        catchupActivate,
        catchupDeactivate,
        watchEverywhereActivate,
        watchEverywhereDeactivate,
        onPurchaseModel,
    };
}
