import usePurchases from '@/src/hooks/usePurchases';
import ModalConfirmPurchase from '@/components/modal/purchases/ModalConfirmPurchase';
import Bundle from '@/src/api/v3/models/Bundle';

/**
 * @param {Offer} offer
 * @param {Object} params
 */
export default function ModalConfirmSubscriptionActivate({ offer, params }) {
    const { subscriptionActivate, bundleActivate } = usePurchases();

    const subscription = offer.subscription;

    async function onConfirm(paymentType) {
        if (subscription instanceof Bundle) {
            await bundleActivate({ offer, model: subscription, paymentType, params });
        } else {
            await subscriptionActivate({ offer, model: subscription, paymentType, params });
        }
    }

    return (
        <ModalConfirmPurchase
            pageTitle={'Подключение подписки ' + subscription.title}
            pageUrl={'#subscription-activate'}
            onConfirm={onConfirm}
        >
            <div className="modal__content-title">
                Стоимость подписки <nobr>{offer.price} ₽ в месяц</nobr>
            </div>
            <div className="modal__content-subtitle">
                Стоимость подписки на библиотеку фильмов «{subscription.title}»<nobr> {offer.price} ₽ в месяц</nobr>.
                Фильмы будут доступны на всех ваших устройствах.
            </div>
        </ModalConfirmPurchase>
    );
}
