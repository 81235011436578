import { useState } from 'react';
import { ButtonClear, ButtonPrimary, ButtonSecondary } from '@/components/button/Button';
import styles from '../Modal.module.scss';
import ModalForm from '@/components/modal/ModalForm';
import InputPinCode from '@/components/input/InputPinCode';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import { format } from '@/src/modules/Common';
import ModalPartnersPhoneReplace from '@/components/modal/partners/ModalPartnersPhoneReplace';
import useModals from '@/src/hooks/useModals';
import ModalPartnersPhoneConfirm from '@/components/modal/partners/ModalPartnersPhoneConfirm';

export default function ModalPartnersPhoneReplaceConfirm({ offer, phone, model }) {
    const { api } = useApiStore();
    const { openModal } = useModals();
    const [submitting, setSubmitting] = useState(false);
    const [repeatSubmitting, setRepeatSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [pinCode, setPinCode] = useState('');

    async function onSubmit(event) {
        event.preventDefault();
        setSubmitting(true);
        setError(null);
        try {
            await api.generalInformation.addContactPhone(phone, pinCode);
            openModal(<ModalPartnersPhoneConfirm offer={offer} model={model} />);
        } catch (e) {
            setError(e.message || 'Ошибка добавления телефона');
            setSubmitting(false);
        }
    }

    function onChange(value) {
        setPinCode(value);
        setError(null);
    }

    async function onRepeatSendSms() {
        setRepeatSubmitting(true);
        try {
            await api.generalInformation.sendConfirmContactPhoneSMSCode(phone);
        } catch (e) {
            setError(e.message || 'Ошибка отправки кода для подтверждения телефона');
        } finally {
            setRepeatSubmitting(false);
        }
    }

    const onAnotherPhoneClick = () => {
        openModal(<ModalPartnersPhoneReplace offer={offer} model={model} />);
    };

    return (
        <ModalForm
            className={'modal--primary'}
            pageUrl={'#partners-phone-replace-confirm'}
            pageTitle={'Подтверждение телефона'}
        >
            <form onSubmit={onSubmit}>
                <div className={styles['modal__content-title']}>Введите код из СМС</div>
                <div className="modal__content-subtitle">
                    Введите код, отправленный на номер {format(phone.slice(1), '+7 (###) ###-##-##')}
                </div>
                <div className="modal__fieldset">
                    <div className="modal__field">
                        <InputPinCode value={pinCode} error={error} onChange={onChange} />
                    </div>
                </div>
                <div className={'modal__btnset'}>
                    <div className="modal__wrapper-btn">
                        <ButtonPrimary
                            title="Продолжить"
                            type="submit"
                            submitting={submitting}
                            disabled={pinCode?.length < 4}
                        />
                    </div>
                    <div className="modal__wrapper-btn">
                        <ButtonSecondary
                            title={'Изменить номер'}
                            className="button--large"
                            onClick={onAnotherPhoneClick}
                        />
                    </div>
                    <div className="modal__wrapper-btn">
                        <ButtonClear
                            title="Отправить код ещё раз"
                            onClick={onRepeatSendSms}
                            submitting={repeatSubmitting}
                        />
                    </div>
                </div>
            </form>
        </ModalForm>
    );
}
