import { useState } from 'react';
import ModalForm from '@/components/modal/ModalForm';
import { ButtonPrimary } from '@/components/button/Button';
import { InputModal } from '@/components/input/Input';
import useModals from '@/src/hooks/useModals';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import ModalPartnersPhoneReplaceConfirm from '@/components/modal/partners/ModalPartnersPhoneReplaceConfirm';
import ModalPartnersPhoneConfirm from '@/components/modal/partners/ModalPartnersPhoneConfirm';

/**
 * @param {Offer} offer
 * @param {BaseModel} model
 */
export default function ModalPartnersPhoneReplace({ offer, model }) {
    const { api } = useApiStore();
    const { openModal } = useModals();

    const [isSubmitting, setSubmitting] = useState(false);
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');

    const subscription = offer.subscription;

    function onPhoneChange(value) {
        const phone = value.replace(/\D/g, '');
        setPhone(phone);
        setError(!phone ? 'Укажите номер телефона' : '');
    }

    function openDialog() {
        openModal(<ModalPartnersPhoneReplaceConfirm offer={offer} phone={phone} model={model} />);
    }

    async function onSubmit(event) {
        event.preventDefault();
        setSubmitting(true);
        try {
            await api.generalInformation.sendConfirmContactPhoneSMSCode(phone);
            openDialog();
        } catch (e) {
            if (e.code === 'SMS_ALREADY_SENDED') {
                openDialog();
            } else if (e.code === 'PHONE_ALREADY_CONFIRMED') {
                openModal(<ModalPartnersPhoneConfirm offer={offer} phone={phone} model={model} />);
            } else {
                setSubmitting(false);
                setError(e.message || 'Ошибка отправки кода для подтверждения телефона');
            }
        }
    }

    return (
        <ModalForm className={'modal--primary'} pageTitle={'Замена номера телефона'} pageUrl={'#partner-phone-replace'}>
            <div className="modal__content-title">Введите номер телефона</div>
            <div className="modal__content-subtitle">
                К этому номеру будет привязан ваш аккаунт в {subscription.partnerTitle}
            </div>
            <form onSubmit={onSubmit}>
                <div className="modal__fieldset">
                    <div className="modal__field">
                        <InputModal
                            title={'Номер телефона'}
                            value={phone}
                            mask="+7 999 999-99-99"
                            required={true}
                            onChange={onPhoneChange}
                            error={error}
                        />
                    </div>
                </div>
                <div className="modal__btnset">
                    <div className="modal__wrapper-btn">
                        <ButtonPrimary
                            title={'Подтвердить'}
                            disabled={phone.length !== 11}
                            type="submit"
                            submitting={isSubmitting}
                        />
                    </div>
                </div>
            </form>
        </ModalForm>
    );
}
