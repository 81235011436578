import usePurchases from '@/src/hooks/usePurchases';
import ModalConfirmPurchase from '@/components/modal/purchases/ModalConfirmPurchase';

/**
 * @param {Offer} offer
 * @param {BaseModel} model
 */
export default function ModalConfirmAssetPurchase({ offer, model }) {
    const { assetPurchase } = usePurchases();

    async function onConfirm(paymentType) {
        await assetPurchase({ model, offer, paymentType });
    }

    return (
        <ModalConfirmPurchase pageTitle={offer.getButtonTitle(model.typeName)} onConfirm={onConfirm}>
            <div className="modal__content-title">{offer.getOfferTextShort()}</div>
            <div className="modal__content-subtitle">{offer.getOfferText(model.title)}</div>
        </ModalConfirmPurchase>
    );
}
